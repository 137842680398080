<template>
  <v-container>
    <p>
         <v-btn x-large v-if="this.$store.state.raceOpen">
           <router-link to="registration" style="text-decoration: none; color: inherit;">Hier anmelden</router-link>
         </v-btn>
    </p>
        <div class="text-h6" v-if="!this.$store.state.raceOpen">
          Die Anmeldung öffnet im Februar 2025
        </div>
        <div class="text-h5">
          Der 14. Lauf gegen Rechts findet am  25. Mai 2025 statt.
        </div>
    <div>
      <p>
      Erneut geht es für uns links um die Alster! Wir freuen uns auf euch. <br/>
      Solidarität statt Nationalismus <br/>
      </p>
      <p>
        In Deutschland und anderen Ländern erleben wir einen besorgniserregenden Anstieg nationalistischer Tendenzen und rechtskonservative Parteien gewinnen an Einfluss.<br/>
        Das gesamte politische Spektrum verschiebt sich nach rechts. Komplexe Probleme, wie Inflation und wirtschaftliche Rezension, werden fälschlicherweise auf Themen wie Migration zurückgeführt. <br/>
        Unter dem Vorwand, Antisemitismus zu bekämpfen, werden oft rassistische Zuweisungen gezielt genutzt und eine Verschärfung der Asyl- und Abschiebepolitik gefordert.<br/>
        Rassismus und Faschismus müssen endlich aus den Köpfen – nur so ist eine solidarische Gesellschaft möglich.
        Wir rufen alle Parteien auf, diese spaltende Rhetorik zu beenden und zu einem faktenbasierten, kompromissbereiten Politikstil zurückzukehren.<br/>
        Die AfD bietet keine Lösungen, sondern verbreitet Hetze und schafft ein Klima der Ausgrenzung gegen Jüdinnen und Juden, People of Color, Schwarze Menschen, Migrant*innen, Rom*nja und Sinti*zze und queere Menschen.<br/>
        Die Geschichte lehrt uns, dass ein solches Klima der Intoleranz schnell in Gewalt umschlagen kann – seit der Wiedervereinigung Deutschlands gab es mindestens 219 Todesopfer rechter Gewalt.<br/>
        Durch rechte Stimmungsmache werden zudem immer mehr LGBTQIA* Personen angegriffen, eingeschüchtert und queere Lebensentwürfe bedroht.<br/>
        Dieser Stimmung und Haltung müssen wir uns klar entgegensetzen.
      </p>
    </div>
    <p>
    Daher laufen wir am 25.05.2025 wieder gegen Rechts und…<br/>
    … gegen jeden Antisemitismus und Rassismus.<br/>
    … gegen Sexismus, Antifeminismus, Homo- und Transphobie und Ableismus. <br/>
    … gegen Faschismus und ein Wiedererstarken faschistischer Parteien in Deutschland.<br/>
    … für Solidarität und gegen Nationalismus.<br/>
    … für eine offene, tolerante, diverse und moderne Gesellschaft.<br/>
    … für den sozialen Zusammenhalt und für gesellschaftliche Teilhabe<br/> 
  </p>
    <p>
    #allezusammengegendenfaschismus #liebdochwenduwillst
#leavenoonebehind #refugeeswelcome #noafd#allcolorsarebeautiful
    </p>

    <p>
      Lauf gegen Rechts 2025: am 25.Mai Alsterwiese Schwanenwik<br/>
      Um die Alster & auch dezentral sowie International<br/>
      Der gesamte Erlös wird antirassistischen Initiativen und Gruppen, die Geflüchtete unterstützen sowie dem Hamburger Bündnis gegen Rechts, gespendet<br/>
    </p>
    <p>
      <a href="https://www.fcstpauli-marathon.de">www.fcstpauli-marathon.de</a><br/>
      <a href="https://www.hbgr.org">www.hbgr.org</a>
    </p>
    <p>
         <v-btn x-large v-if="this.$store.state.raceOpen">
           <router-link to="registration" style="text-decoration: none; color: inherit;">Hier anmelden</router-link>
         </v-btn>
    </p>

  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "RaceStatus",
  data: () => ({
    raceInfo: {}
  }),
  mounted: function () {
    this.getRace()
  },
  methods: {
    getRace() {
      const url = `${this.$base_url}/race/`

      axios.get(url)
          .then((response) => {
            this.raceInfo = response.data
            this.$store.commit('setRaceStatus', response.data.isOpen)
            this.$store.commit('setOnSite', response.data.onSiteEnabled)
            this.$store.commit('setShirtsEnabled', response.data.shirtsEnabled)
          })
    }
  }
}

</script>

<style scoped>

</style>
